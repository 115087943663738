import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95vh"
          image={`url(${mobileHero}) bottom/ cover no-repeat`}
          column
          justifyBetween
          textCenter
        >
          <Header />
          <CFView pulsate mb="20px">
            <OrderPickupButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <Header />
        <CFView
          h="95vh"
          maxHeight="670px"
          w="100%"
          image={`url(${hero}) 45% 0 / cover no-repeat`}
          zIndex={90}
          column
          justifyCenter
          alignEnd
        >
          <CFView column center mr="5%">
            <CFImage
              w="50vw"
              minWidth="500px"
              maxWidth="615px"
              src={heroText}
              alt="Sushi Tendo hero text"
              zIndex={98}
            />
            <CFView pulsate mt="25px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
